<template>
  <v-layout fill-height>
    <toolbar v-if="!disabled" :pathBackButton="pathBackButton" :title="$t('formulario.titulo')" />
    <toolbar v-else :pathBackButton="pathBackButton" :title="$t('dadosEntrevistado.titulo')" />
    <v-layout justify-center fill-height>
      <v-flex>
        <v-card class="fill-height">
            <v-stepper v-model="e1" class="fill-height">
              <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1">{{ $t('stepper.dadosPessoais') }}</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" step="2">{{ $t('stepper.dadosBiologicos') }}</v-stepper-step>

                <v-divider v-if="getUserData.code.fields.vidaSaude"></v-divider>

                <v-stepper-step :complete="e1 > 3" step="3" v-if="getUserData.code.fields.vidaSaude">{{ $t('stepper.programaVidaSaude') }}</v-stepper-step>

                <v-divider v-if="isCovidOptionSelected && getUserData.code.fields.covid"></v-divider>

                <v-stepper-step :step="getStepCovid()" v-if="isCovidOptionSelected && getUserData.code.fields.covid">Covid-19</v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-form ref="formstep1" v-model="valid" lazy-validation class="fill-height">
                    <v-layout wrap>
                      <v-flex xs12 sm6 md4>
                        <v-text-field
                          data-cy="nameInitials"
                          v-model="interviewee.nameInitials"
                          :rules="fieldRules"
                          :label="$t('entrevistado.iniciaisNome')"
                          mask="AAAAAA"
                          :disabled="disabled"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md4>
                        <v-text-field
                          data-cy="age"
                          v-model="interviewee.age"
                          :rules="fieldRules"
                          :label="$t('entrevistado.idade')"
                          :disabled="disabled"
                          mask="###"
                          required
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="!getUserData.code.fields.whichReligion">
                        <v-text-field
                          data-cy="referredReligion"
                          v-model="interviewee.referredReligion"
                          :rules="fieldRules"
                          :label="$t('entrevistado.religiaoReferida')"
                          :disabled="disabled"
                          required
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="!getUserData.code.fields.whichReligion">
                        <v-text-field
                          data-cy="howManyYearsAgo"
                          v-model="interviewee.howManyYearsAgo"
                          :rules="fieldRules"
                          :label="$t('entrevistado.haQuantosAnos')"
                          :disabled="disabled"
                          mask="###"
                          required
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="getUserData.code.fields.whichReligion">
                        <v-text-field
                          data-cy="whichReligion"
                          v-model="interviewee.whichReligion"
                          :rules="fieldRules"
                          :label="$t('entrevistado.qualReligiao')"
                          :disabled="disabled"
                          required
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6 md4>
                        <v-select
                          data-cy="schooling"
                          v-model="interviewee.schooling"
                          :items="schoolingItems"
                          :rules="fieldRules"
                          :label="$t('entrevistado.escolaridade')"
                          :disabled="disabled"
                          box
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4
                        v-if="getUserData.code.fields.monthlyIncome"
                      >
                        <v-select
                          data-cy="monthlyIncome"
                          v-model="interviewee.monthlyIncome"
                          :items="monthlyIncomeItems"
                          :rules="fieldRules"
                          :label="$t('entrevistado.rendaFamiliar.titulo')"
                          :disabled="disabled"
                          box
                          required
                        ></v-select>
                        <span class="body-1">
                          {{ $t('entrevistado.rendaFamiliar.obs') }}
                        </span>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="!getUserData.code.fields.gender">
                        <div>{{ $t('entrevistado.sexo') }}</div>
                        <v-radio-group
                          v-model="interviewee.gender"
                          :rules="fieldRules"
                          row
                          required
                          :disabled="disabled"
                          @change="onChangeGender"
                        >
                          <v-radio data-cy="male" :label="$t('sexo.masculino')" value="male"></v-radio>
                          <v-radio data-cy="female" :label="$t('sexo.feminino')" value="female"></v-radio>
                        </v-radio-group>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="getUserData.code.fields.gender">
                        <div>{{ $t('entrevistado.sexo') }}</div>
                        <v-radio-group
                          v-model="interviewee.gender"
                          :rules="fieldRules"
                          row
                          required
                          :disabled="disabled"
                          @change="onChangeGender"
                        >
                          <v-radio data-cy="male" :label="$t('sexo.masculino')" value="male"></v-radio>
                          <v-radio data-cy="female" :label="$t('sexo.feminino')" value="female"></v-radio>
                          <v-radio data-cy="unknown" :label="$t('sexo.prefiroNaoDizer')" value="unknown"></v-radio>
                        </v-radio-group>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="!disabled || interviewee.job">
                        <v-text-field
                          data-cy="job"
                          v-model="interviewee.job"
                          :label="$t('entrevistado.profissao')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          required
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="!disabled || interviewee.civilStatus">
                        <v-select
                          data-cy="civilStatus"
                          v-model="interviewee.civilStatus"
                          :items="civilStatusItems"
                          :label="$t('entrevistado.estadoCivil')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          box
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="!disabled || interviewee.skinColor">
                        <v-select
                          data-cy="skinColor"
                          v-model="interviewee.skinColor"
                          :items="skinColorItems"
                          :label="$t('entrevistado.corPele')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          box
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="(!disabled || interviewee.habitation) && !getUserData.code.fields.habitationV2">
                        <v-select
                          data-cy="habitation"
                          v-model="interviewee.habitation"
                          :items="habitationItemsV1"
                          :label="$t('entrevistado.moradia')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          box
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="(!disabled || interviewee.habitation) && getUserData.code.fields.habitationV2">
                        <v-select
                          data-cy="habitation"
                          v-model="interviewee.habitation"
                          :items="habitationItemsV2"
                          :label="$t('entrevistado.moradia')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          box
                          required
                        ></v-select>
                      </v-flex>
                    </v-layout>

                    <v-layout align-center justify-end row fill-height>
                      <v-btn data-cy="next-1" color="primary" @click="nextStep()">{{ $t('navegacao.avancar') }}</v-btn>
                    </v-layout>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-form ref="formstep2" v-model="valid" lazy-validation class="fill-height">
                    <v-layout wrap>
                      <v-flex xs12 sm6 md4>
                        <v-select
                          data-cy="referredDiseases"
                          v-model="interviewee.referredDiseases"
                          :items="referredDiseasesItems"
                          :label="$t('entrevistado.doencasReferidas')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          box
                          chips
                          multiple
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="isOtherOptionSelected">
                        <v-text-field
                          data-cy="otherRefferedDisease"
                          v-model="interviewee.otherRefferedDisease"
                          :label="$t('entrevistado.outraDoencaReferida')"
                          :disabled="disabled"
                          :rules="fieldRules"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="getUserData.code.fields.covid">
                        <v-select
                          data-cy="vaccinatedAgainstCovid"
                          v-model="interviewee.vaccinatedAgainstCovid"
                          :items="vaccinatedAgainstCovidItems"
                          :label="$t('entrevistado.vacinadoContraCovid')"
                          :disabled="disabled"
                          box
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4
                        v-if="isVaccinatedYesSelected && getUserData.code.fields.covid"
                      >
                        <v-select
                          data-cy="whenVaccinatedCovid"
                          v-model="interviewee.whenVaccinatedCovid"
                          :items="whenVaccinatedCovidItems"
                          :label="$t('entrevistado.quandoTomouVacina')"
                          :disabled="disabled"
                          box
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="getUserData.code.fields.covid">
                        <v-select
                          data-cy="exposureDegreeCovid"
                          v-model="interviewee.exposureDegreeCovid"
                          :items="exposureDegreeCovidItems"
                          :label="$t('entrevistado.exposicaoAoCovid')"
                          :disabled="disabled"
                          box
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4
                        v-if="(!disabled || interviewee.gynecologicalDisease) && interviewee.gender === 'female' && getUserData.code.fields.gynecologicalDisease"
                      >
                        <v-select
                          data-cy="gynecologicalDisease"
                          v-model="interviewee.gynecologicalDisease"
                          :items="yesNoItems"
                          :label="$t('entrevistado.doencaGinecologica')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          box
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4
                        v-if="(!disabled || interviewee.gynecologicalDisease) && interviewee.gynecologicalDisease === $t('sim')"
                      >
                        <v-text-field
                          data-cy="whichGynecologicalDisease"
                          v-model="interviewee.whichGynecologicalDisease"
                          :label="$t('entrevistado.qualDoencaGinecologica')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          required
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="isDiabetesOptionSelected">
                        <v-select
                          data-cy="takeInsulin"
                          v-model="interviewee.takeInsulin"
                          :items="yesNoItems"
                          :label="$t('entrevistado.tomaInsulina')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          box
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="!disabled || interviewee.weight">
                        <v-text-field
                          data-cy="weight"
                          placeholder="Ex: 60,58"
                          v-model="interviewee.weight"
                          :label="$t('entrevistado.peso').concat(' (kg)')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          :mask="weightMask"
                          required
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="!disabled || interviewee.height">
                        <v-text-field
                          data-cy="height"
                          placeholder="Ex: 1,67"
                          v-model="interviewee.height"
                          :label="$t('entrevistado.altura').concat(' (m)')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          mask="#,##"
                          required
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="!disabled || interviewee.imc">
                        <v-text-field
                          data-cy="imc"
                          v-model="interviewee.imc"
                          :label="$t('entrevistado.imc').concat(' (kg/m²)')"
                          :disabled="disabled"
                          readonly
                          required
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="(!disabled || interviewee.sbp) && !getUserData.code.fields.sbp">
                        <span class="label">{{ $t('entrevistado.pressaoArterial') }}</span>
                        <v-layout>
                          <v-text-field
                            data-cy="sbp"
                            placeholder="Ex: 12"
                            v-model="interviewee.sbp"
                            :disabled="disabled"
                            :rules="fieldRules"
                            mask="NN"
                            required
                          ></v-text-field>
                          <h2 style="align-self: center; margin: 20px">X</h2>
                          <v-text-field
                            data-cy="dbp"
                            placeholder="Ex: 8"
                            v-model="interviewee.dbp"
                            :disabled="disabled"
                            :rules="fieldRules"
                            mask="NN"
                            required
                          ></v-text-field>
                        </v-layout>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="(!disabled || interviewee.sbp) && getUserData.code.fields.sbp">
                        <span class="label">{{ $t('entrevistado.pressaoArterial') }}</span>
                        <v-layout>
                          <v-text-field
                            data-cy="sbp"
                            placeholder="Ex: 12"
                            v-model="interviewee.sbp"
                            :disabled="disabled"
                            :rules="fieldRules"
                            mask="NN"
                            required
                          ></v-text-field>
                          <h2 style="align-self: center; margin: 20px">X</h2>
                          <v-text-field
                            data-cy="dbp"
                            placeholder="Ex: 8"
                            v-model="interviewee.dbp"
                            :disabled="disabled"
                            :rules="fieldRules"
                            mask="NN"
                            required
                          ></v-text-field>
                        </v-layout>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="!disabled || interviewee.physicalHealth">
                        <v-select
                          data-cy="physicalHealth"
                          v-model="interviewee.physicalHealth"
                          :items="physicalHealthItems"
                          :label="$t('entrevistado.saudeFisica')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          box
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="!disabled || interviewee.mentalHealth">
                        <v-select
                          data-cy="mentalHealth"
                          v-model="interviewee.mentalHealth"
                          :items="mentalHealthItems"
                          :label="$t('entrevistado.saudeMental')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          box
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="!disabled || interviewee.lifeQuality">
                        <v-select
                          data-cy="lifeQuality"
                          v-model="interviewee.lifeQuality"
                          :items="lifeQualityItems"
                          :label="$t('entrevistado.qualidadeVida')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          box
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="!disabled || interviewee.whatToImprove">
                        <v-text-field
                          data-cy="whatToImprove"
                          v-model="interviewee.whatToImprove"
                          :label="$t('entrevistado.oqueDesejaMelhorar')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          required
                        ></v-text-field>
                      </v-flex>
                    </v-layout>

                    <v-layout align-center justify-end row fill-height>
                      <v-btn flat data-cy="back-2" @click="backStep()">Voltar</v-btn>
                      <v-btn v-if="isCovidOptionSelected || !disabled" data-cy="next-2" color="primary" @click="nextStep()">{{ $t('navegacao.avancar') }}</v-btn>
                    </v-layout>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content step="3" v-if="disabled || getUserData.code.fields.vidaSaude">
                  <v-form ref="formstep3" v-model="valid" lazy-validation class="fill-height">
                    <v-layout wrap>
                      <v-flex x12 sm12 md12>
                        <h1>Programa Vida e Saúde</h1>
                      </v-flex>

                      <v-flex v-if="!disabled || interviewee.howKnown" xs12 sm6 md4>
                        <v-select
                          data-cy="howKnown"
                          v-model="interviewee.howKnown"
                          :items="howKnownItems"
                          :rules="fieldRules"
                          :label="$t('entrevistado.vidaSaude.comoConheceu')"
                          :disabled="disabled"
                          box
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex v-if="!disabled || interviewee.howMuchWatch" xs12 sm6 md4>
                        <v-select
                          data-cy="howMuchWatch"
                          v-model="interviewee.howMuchWatch"
                          :items="howMuchWatchItems"
                          :rules="fieldRules"
                          :label="$t('entrevistado.vidaSaude.quantoTempoAssiste')"
                          :disabled="disabled"
                          box
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex v-if="!disabled || interviewee.frequencyWatch" xs12 sm6 md4>
                        <v-select
                          data-cy="frequencyWatch"
                          v-model="interviewee.frequencyWatch"
                          :items="frequencyWatchItems"
                          :rules="fieldRules"
                          :label="$t('entrevistado.vidaSaude.frequenciaAssiste')"
                          :disabled="disabled"
                          box
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex v-if="!disabled || interviewee.howWatch" xs12 sm6 md4>
                        <v-select
                          data-cy="howWatch"
                          v-model="interviewee.howWatch"
                          :items="howWatchItems"
                          :rules="fieldRules"
                          :label="$t('entrevistado.vidaSaude.comoAssiste')"
                          :disabled="disabled"
                          box
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex v-if="!disabled || interviewee.sectionAttractsMore" xs12 sm6 md4>
                        <v-select
                          data-cy="sectionAttractsMore"
                          v-model="interviewee.sectionAttractsMore"
                          :items="sectionAttractsItems"
                          :rules="fieldRules"
                          :label="$t('entrevistado.vidaSaude.qualSecaoMaisAtrai')"
                          :disabled="disabled"
                          box
                          required
                        ></v-select>

                        <!-- <EnumerateInput ref="enumeratedInput" :items="sectionAttractsItems" :disabled="disabled"/> -->
                      </v-flex>

                      <v-flex v-if="!disabled || interviewee.sectionAttractsLess" xs12 sm6 md4>
                        <v-select
                          data-cy="sectionAttractsLess"
                          v-model="interviewee.sectionAttractsLess"
                          :items="sectionAttractsItems"
                          :rules="fieldRules"
                          :label="$t('entrevistado.vidaSaude.qualSecaoMenosAtrai')"
                          :disabled="disabled"
                          box
                          required
                        ></v-select>

                        <!-- <EnumerateInput ref="enumeratedInput" :items="sectionAttractsItems" :disabled="disabled"/> -->
                      </v-flex>

                      <v-flex xs12>
                        <v-text-field
                          data-cy="suggestionsProgram"
                          v-model="interviewee.suggestionsProgram"
                          :label="$t('entrevistado.vidaSaude.sugestoes')"
                          :disabled="disabled"
                          :rules="fieldRules"
                          required
                        ></v-text-field>
                      </v-flex>
                    </v-layout>

                    <v-layout align-center justify-end row fill-height>
                      <v-btn flat data-cy="back-3" @click="backStep()">Voltar</v-btn>
                      <v-btn v-if="!disabled" data-cy="next-3" color="primary" @click="nextStep()">{{ $t('navegacao.avancar') }}</v-btn>
                    </v-layout>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content :step="getStepCovid()" v-if="isCovidOptionSelected && getUserData.code.fields.covid">
                  <v-form :ref="getUserData.code.fields.vidaSaude ? 'formstep4' : 'formstep3'" v-model="valid" lazy-validation class="fill-height">
                    <v-layout wrap>
                      <v-flex x12 sm12 md12>
                        <h1>Covid-19</h1>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="isCovidOptionSelected">
                        <v-select
                          data-cy="severityLevelCovid"
                          v-model="interviewee.severityLevelCovid"
                          :items="severityLevelCovidItems"
                          :label="$t('entrevistado.nivelGravidade')"
                          :disabled="disabled"
                          box
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="isCovidOptionSelected && interviewee.severityLevelCovid === $t('selects.nivelGravidade.gravissimo')">
                        <v-select
                          data-cy="sequelaeCovid"
                          v-model="interviewee.sequelaeCovid"
                          :items="yesNoItems"
                          :label="$t('entrevistado.sequelas')"
                          :disabled="disabled"
                          box
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="isCovidOptionSelected">
                        <v-select
                          data-cy="earlyTreatmentCovid"
                          v-model="interviewee.earlyTreatmentCovid"
                          :items="yesNoItems"
                          :label="$t('entrevistado.tratamentoPrecoce')"
                          :disabled="disabled"
                          box
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="isCovidOptionSelected">
                        <v-select
                          data-cy="covid19Symptoms"
                          v-model="interviewee.covid19Symptoms"
                          :items="covid19SymptomsItems"
                          :rules="fieldRules"
                          :label="$t('entrevistado.sintomasCovid19')"
                          :disabled="disabled"
                          box
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md4 v-if="isCovidOptionSelected">
                        <v-select
                          data-cy="covid19"
                          v-model="interviewee.covid19"
                          :items="covid19Items"
                          :rules="fieldRules"
                          :label="$t('entrevistado.covid19')"
                          :disabled="disabled"
                          box
                          required
                        ></v-select>
                      </v-flex>

                      <v-flex
                        xs12
                        sm6
                        md4
                        v-if="isCovidOptionSelected && isHospitalTreatmentOptionSelected"
                      >
                        <v-select
                          data-cy="hospitalTreatment"
                          v-model="interviewee.hospitalTreatment"
                          :items="hospitalTreatmentItems"
                          :rules="fieldRules"
                          :label="$t('entrevistado.tratamentoHospitalar')"
                          :disabled="disabled"
                          box
                          required
                        ></v-select>
                      </v-flex>
                    </v-layout>

                    <v-layout align-center justify-end row fill-height>
                      <v-btn flat data-cy="back-4" @click="backStep()">Voltar</v-btn>
                      <v-btn v-if="!disabled" data-cy="next-4" color="primary" @click="nextStep()">{{ $t('navegacao.avancar') }}</v-btn>
                    </v-layout>
                  </v-form>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
        </v-card>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import Toolbar from '@/components/toolbar/Toolbar'
import util from '@/router/util'
import { mapGetters, mapActions } from 'vuex'
import DialogService from '@/components/dialogs/generic/DialogService'

export default {
  name: 'Form',
  components: {
    Toolbar
  },
  data: () => {
    return {
      e1: 1,
      util: util,
      valid: false,
      disabled: false,
      weightMask: '##,##',
      fieldRules: [],
      arrayRules: [],
      civilStatusItems: [],
      skinColorItems: [],
      habitationItemsV1: [],
      habitationItemsV2: [],
      jobItems: [],
      schoolingItems: [],
      monthlyIncomeItems: [],
      physicalHealthItems: [],
      mentalHealthItems: [],
      lifeQualityItems: [],
      referredDiseasesItems: [],
      covid19Items: [],
      hospitalTreatmentItems: [],
      vaccinatedAgainstCovidItems: [],
      whenVaccinatedCovidItems: [],
      exposureDegreeCovidItems: [],
      severityLevelCovidItems: [],
      covid19SymptomsItems: [],
      yesNoItems: [],
      interviewee: {
        referredDiseases: [],
        sectionAttracts: []
      },
      howKnownItems: [],
      howMuchWatchItems: [],
      frequencyWatchItems: [],
      howWatchItems: [],
      sectionAttractsItems: []
    }
  },
  created () {
    this.interviewee = this.getInterviewee
  },
  mounted () {
    this.fieldRules = [(v) => !!v || this.$t('validacao.campoObrigatorio')]
    this.arrayRules = [(v) => (v && v.length !== 0) || this.$t('validacao.campoObrigatorio')]
    this.civilStatusItems = Object.values(this.$t('selects').estadoCivil)
    this.skinColorItems = Object.values(this.$t('selects').corPele)
    this.habitationItemsV1 = Object.values(this.$t('selects').moradia.v1)
    this.habitationItemsV2 = Object.values(this.$t('selects').moradia.v2)
    this.schoolingItems = Object.values(this.$t('selects').escolaridade)
    this.monthlyIncomeItems = Object.values(this.$t('selects').rendaFamiliar)
    this.physicalHealthItems = Object.values(this.$t('selects').saude)
    this.mentalHealthItems = Object.values(this.$t('selects').saude)
    this.lifeQualityItems = Object.values(this.$t('selects').saude)
    this.referredDiseasesItems = Object.values(
      this.$t('selects').doencasReferidas
    )
    this.covid19Items = Object.values(this.$t('selects').covid19)
    this.hospitalTreatmentItems = Object.values(
      this.$t('selects').tratamentoHospitalar
    )
    this.covid19SymptomsItems = Object.values(
      this.$t('selects').sintomasCovid19
    )
    this.vaccinatedAgainstCovidItems = Object.values(
      this.$t('selects').vacinadoContraCovid
    )
    this.whenVaccinatedCovidItems = Object.values(
      this.$t('selects').quandoTomouVacina
    )
    this.exposureDegreeCovidItems = Object.values(
      this.$t('selects').exposicaoAoCovid
    )
    this.severityLevelCovidItems = Object.values(
      this.$t('selects').nivelGravidade
    )
    this.yesNoItems = Object.values(
      this.$t('selects').simNao
    )
    this.howKnownItems = Object.values(this.$t('selects').vidaSaude.comoConheceu)
    this.howMuchWatchItems = Object.values(this.$t('selects').vidaSaude.quantoTempoAssiste)
    this.frequencyWatchItems = Object.values(this.$t('selects').vidaSaude.frequenciaAssiste)
    this.howWatchItems = Object.values(this.$t('selects').vidaSaude.comoAssiste)
    this.sectionAttractsItems = Object.values(this.$t('selects').vidaSaude.qualSecaoMaisAtrai)

    if (this.$route.params.interviewee) {
      this.interviewee = this.$route.params.interviewee
      this.disabled = true
    }
  },
  computed: {
    ...mapGetters(['getQuestionnaireType', 'getInterviewee', 'getUserData']),
    pathBackButton () {
      if (this.getUserData.isAnonym) {
        return util.path.research.TERMS_AND_CONDITION
      }
      return this.$route.params.pathBackButton
        ? this.$route.params.pathBackButton
        : util.path.research.TAB_QUESTIONNAIRE
    },
    splitPressure () {
      return this.interviewee.sbp.split('x')
    },
    imc () {
      return this.interviewee.weight | this.interviewee.height
    },
    isCovidOptionSelected () {
      return this.interviewee.referredDiseases.includes(
        this.$t('selects.doencasReferidas.covid19')
      )
    },
    isHospitalTreatmentOptionSelected () {
      return this.interviewee.covid19 === this.$t('selects.covid19.tratamentoHospitalar')
    },
    isOtherOptionSelected () {
      return this.interviewee.referredDiseases.includes(
        this.$t('selects.doencasReferidas.outra')
      )
    },
    isDiabetesOptionSelected () {
      return this.interviewee.referredDiseases.includes(
        this.$t('selects.doencasReferidas.diabetes')
      )
    },
    isGynecologicalDiseaseYesSelected () {
      return this.interviewee.gynecologicalDisease === this.$t('sim')
    },
    isVaccinatedYesSelected () {
      if (this.interviewee.vaccinatedAgainstCovid) {
        return this.interviewee.vaccinatedAgainstCovid !== this.$t('selects.vacinadoContraCovid.nao')
      }
      return false
    }
  },
  watch: {
    imc: 'calculateIMC',
    'interviewee.weight': function (newValue, oldValue) {
      this.weightMask = newValue.length === 3 ? '#,###'
        : this.weightMask = newValue.length === 4 ? '##,###' : '###,##'
    }
  },
  methods: {
    ...mapActions(['saveInterviewee']),
    getStepCovid () {
      return this.getUserData.code.fields.vidaSaude ? 4 : 3
    },
    openQuestionnaire () {
      if (this.$refs.formstep2.validate()) {
        this.clearCovidFields()
        this.saveInterviewee({ interviewee: this.interviewee })
        this.$router.push(
          util.path.research.RESEARCH_QUESTIONNAIRE.concat('/')
            .concat(this.getQuestionnaireType)
            .concat('/1')
        )
      }
    },
    calculateIMC () {
      if (this.interviewee.weight && this.interviewee.height) {
        const formatedWeight = this.interviewee.weight.length === 2 ? this.interviewee.weight * 100 : this.interviewee.weight
        this.interviewee.imc = (
          formatedWeight / (this.interviewee.height / 10) ** 2
        ).toFixed(2)
      }
    },
    backStep () {
      this.e1--
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    nextStep () {
      if (this.getUserData.code.fields.vidaSaude && this.e1 === 3) {
        if (this.isCovidOptionSelected && this.getUserData.code.fields.covid) {
          if (this.$refs.formstep3.validate()) {
            this.e1++
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
          } else {
            DialogService.on('DefaultDialog', this.$t('formulario.modal.titulo'), this.$t('formulario.modal.mensagem'))
          }
        } else {
          if (this.$refs.formstep3.validate()) {
            this.openQuestionnaire()
          } else {
            DialogService.on('DefaultDialog', this.$t('formulario.modal.titulo'), this.$t('formulario.modal.mensagem'))
          }
        }
      } else if (this.e1 === 2) {
        if (this.isCovidOptionSelected || this.getUserData.code.fields.vidaSaude) {
          if (this.$refs.formstep2.validate()) {
            this.e1++
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
          } else {
            DialogService.on('DefaultDialog', this.$t('formulario.modal.titulo'), this.$t('formulario.modal.mensagem'))
          }
        } else {
          if (this.$refs.formstep2.validate()) {
            this.openQuestionnaire()
          } else {
            DialogService.on('DefaultDialog', this.$t('formulario.modal.titulo'), this.$t('formulario.modal.mensagem'))
          }
        }
      } else if (this.e1 === 4) {
        if (this.$refs.formstep4.validate()) {
          this.openQuestionnaire()
        } else {
          DialogService.on('DefaultDialog', this.$t('formulario.modal.titulo'), this.$t('formulario.modal.mensagem'))
        }
      } else if (this.e1 === 3) {
        if (this.$refs.formstep3.validate()) {
          this.openQuestionnaire()
        } else {
          DialogService.on('DefaultDialog', this.$t('formulario.modal.titulo'), this.$t('formulario.modal.mensagem'))
        }
      } else {
        if (this.$refs.formstep1.validate()) {
          this.e1++
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        } else {
          DialogService.on('DefaultDialog', this.$t('formulario.modal.titulo'), this.$t('formulario.modal.mensagem'))
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        }
      }
    },
    clearCovidFields () {
      if (!this.interviewee.referredDiseases.includes(
        this.$t('selects.doencasReferidas.covid19')
      )) {
        this.interviewee.covid19Symptoms = ''
        this.interviewee.covid19 = ''
        this.interviewee.hospitalTreatment = ''
      }
    },
    onChangeGender () {
      delete this.interviewee.gynecologicalDisease
      delete this.interviewee.whichGynecologicalDisease
    }
  }
}
</script>

<style>
.layout > .flex {
  padding: 8px;
}
.label {
  position: absolute;
}

@media (max-width: 450px) {
  .v-text-field label {
    font-size: 0.8em;
  }
  .v-list__tile__title {
    font-size: 0.7em;
  }
  .v-select__slot {
    height: 70px;
  }
  .v-select__slot .v-label {
    overflow: inherit;
    white-space: normal;
  }
}
</style>
