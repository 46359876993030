<template>
  <v-toolbar color="primary" app dark :flat="isFlat">
    <v-btn aria-label="Voltar" icon v-if="pathBackButton">
      <v-icon color="white" @click="$router.push(pathBackButton)">arrow_back</v-icon>
    </v-btn>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer v-if="itemsRight"></v-spacer>
    <v-toolbar-items class="align-center">
      <slot></slot>
    </v-toolbar-items>
  </v-toolbar>
</template>
<script>
export default {
  name: 'Toolbar',
  props: {
    pathBackButton: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    itemsRight: {
      type: Boolean,
      required: false,
      default: false
    },
    isFlat: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => {
    return {
    }
  }
}
</script>
